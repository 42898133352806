<section class="service flex flex-column">
  <div>
    <h2 class="title">
      <ng-content select="[title]"></ng-content>
    </h2>
    <p class="subtitle">
      <ng-content select="[subtitle]"></ng-content>
    </p>
  </div>
  <div class="flex gap-4 md:gap-5 service-items">
    <ng-content></ng-content>
  </div>
</section>
