import {Component, HostBinding, Input} from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-feature-section',
  templateUrl: './feature-section.component.html',
  imports: [
    NgClass
  ],
  styleUrls: ['./feature-section.component.scss']
})
export class FeatureSectionComponent {
  @Input() variant: string = 'image-text';

  @HostBinding('class') get variantClass(): string {
    return this.variant !== 'normal' ? `${this.variant}` : '';
  }
}
