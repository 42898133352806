import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss']
})
export class ServiceSectionComponent {

}
