<div class="hero flex align-items-center flex flex-column-reverse md:flex-row">
  <div class="text-block text-center md:text-left">
    <h1 class="title">
      <ng-content select="[title]"></ng-content>
    </h1>
    <p class="subtitle mb-5">
      <ng-content select="[subtitle]"></ng-content>
    </p>
    <ng-content></ng-content>
  </div>
  <div class="image-block">
    <ng-content select="[image]"></ng-content>
  </div>
</div>
